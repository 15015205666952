import { mapState } from "vuex";

export const layoutComputed = {
  ...mapState("layout", {
    layoutType: state => state.layoutType,
    leftSidebarType: state => state.leftSidebarType,
    layoutWidth: state => state.layoutWidth,
    loader: state => state.loader,
    isMenuCondensed: state => state.isMenuCondensed
  })
};
